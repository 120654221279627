<template>
    <v-dialog
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="500px"
        min-width="500px"
        max-width="600px"
    >
        <v-card>
            <v-card-title class="success white--text justify-space-between">
                <span>{{ updateMode == false ? $t('message.addCountry') : $t('message.updateCountry') + ": " + countryName }}</span>
                <HelpPopUpV2 help-page-component="AddShippingPort" :light="true" :no-padding="true"/>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                            />
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="countryForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.name') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                    :rules="[...validationRules.required, ...validationRules.plainText]"
                                    :value="Country__name"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Country__name = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row v-if="false">
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.alternateName') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                    :value="Country__alt_name"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Country__alt_name = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.code') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                :rules="[...validationRules.required, ...validationRules.plainText]"
                                :value="Country__code"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                @change="Country__code = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.continent') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-select
                                :items="continents"
                                :rules="[...validationRules.required]"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                v-model="Country__continent_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.defaultPort') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                :items="allShippingPorts"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="Shippingport.title"
                                item-value="Shippingport.id"
                                solo
                                v-model="Country__shippingport_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.eutrComplianceRequired') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-checkbox
                                :true-value="1"
                                class="mt-0"
                                dense
                                hide-details="auto"
                                v-model="Country__eutr_compliance_required"
                            />
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                        :loading="loading.add"
                        color="info"
                        small
                        @click="addCountry()"
                        v-if="updateMode == false"
                >{{ $t('message.add') }}</v-btn>
                <v-btn
                        :loading="loading.save"
                        color="info"
                        small
                        @click="saveCountry()"
                        v-if="updateMode == true"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {isObject} from "Helpers/helpers";
const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");

export default {
    name: "AddCountry",
    components: {HelpPopUpV2},
    props: ['countryId','countryName','dialog','updateMode','dialog-closed','update-done','create-done'],
    data() {
        return {
            dialogs: {
                error: false,
                error_message: ""
            },
            loading: {
                add: false,
                get: false,
                save: false
            },
            edit_dialog: false,
            // rules: {
            //     name: v => !!v || this.$t('message.required'),
            //     code: v => !!v || this.$t('message.required'),
            //     continent: v => !!v || this.$t('message.required'),
            // },
            validForm: true,
        }
    },
    computed: {
        statePrefix() {
            return this.updateMode ? 'update' : 'current'
        },
        ...mapFieldsPrefixed('country', {
            Country__id: 'Country.id',
            Country__alt_name: 'Country.alt_name',
            Country__code: 'Country.code',
            Country__continent_id: 'Country.continent_id',
            Country__eutr_compliance_required: 'Country.eutr_compliance_required',
            Country__name: 'Country.name',
            Country__shippingport_id: 'Country.shippingport_id',
        },'statePrefix'),
        ...mapGetters('shippingport',{
            allShippingPorts: 'allShippingPorts'
        }),
        ...mapGetters([
            'continents',
            'validationRules'
        ]),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        ...mapActions('country',{
            createCountry: 'createCountry',
            getCountryToUpdateById: 'getCountryToUpdateById',
            resetCountryToUpdate: 'resetCountryToUpdate',
            resetCurrentState: 'resetCurrentState',
            updateCountry: 'updateCountry'
        }),
        addCountry () {
            if(this.$refs.countryForm.validate()) {
                this.loading.add = true
                this.createCountry()
                    .then((response) => {
                        if (response.status === 'success') {
                            this.$toast.success(this.$t('message.successes.countryAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.$emit('create-done')
                            this.editDialog = false
                        } else {
                            if(isObject(response.message)){
                                //extract validation errors
                                const errors = Object.values(response.message)
                                errors.forEach(errorObj => {
                                    if (Array.isArray(errorObj)) {
                                        errorObj.forEach(error => {
                                            this.$toast.error(error,
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        })
                                    } else {
                                        this.$toast.error(this.$t('message.errors.countryNotAdded'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    }
                                })
                            } else {
                                this.$toast.error(this.$t('message.errors.countryNotAdded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                            this.loading.add = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.countryNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.countryNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        saveCountry () {
            if(this.$refs.countryForm.validate()) {
                this.loading.save = true
                this.updateCountry()
                    .then((response) => {
                        if (response.status == 'success') {
                            this.$toast.success(this.$t('message.successes.countryUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('update-done')
                        } else {
                            if(isObject(response.message)){
                                //extract validation errors
                                const errors = Object.values(response.message)
                                errors.forEach(errorObj => {
                                    if (Array.isArray(errorObj)) {
                                        errorObj.forEach(error => {
                                            this.$toast.error(error,
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        })
                                    } else {
                                        this.$toast.error(this.$t('message.errors.countryNotUpdated'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    }
                                })
                            } else {
                                this.$toast.error(this.$t('message.errors.countryNotUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                            this.loading.save = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.countryNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.countryNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
               if(this.updateMode == true) {
                   this.loading.get = true
                   this.getCountryToUpdateById(this.countryId)
                       .then(() => {
                           this.loading.get = false
                       })
               } else {
                   this.resetCurrentState()
               }
            } else {
                if(this.updateMode == true) {
                    this.resetCountryToUpdate()
                } else {
                    this.resetCurrentState()
                }
            }
            this.edit_dialog = value
        }
    }
}
</script>